import React, { useState } from 'react';
import { IResolveParams, LoginSocialTwitter } from 'reactjs-social-login';
import { TwitterLoginButton } from 'react-social-login-buttons';
import { useDispatch } from 'react-redux';
import { loginApiCall } from '../redux/auth/authSlice';

const Twitter: React.FC = () => {
  const REDIRECT_URI = window.location.href;
  const dispatch = useDispatch();
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState<any>();

  const onResolve = ({ provider, data }: IResolveParams) => {
    setProvider(provider);
    setProfile(data);
    const { name, id, profile_image_url }: any = data;
    if (id) {
      const registerResponse = {
        name,
        social_media_id: id,
        type: 4,
        profile_icon: profile_image_url,
      };
      const loginResponse = {
        social_media_id: id,
        type: 4,
      };
      dispatch(loginApiCall({ loginResponse, registerResponse }));
    }
    setTimeout(() => {
      window.close();
    },3000);
  };

  return (
    <>
      <div className={`App ${provider && profile ? 'hide' : ''}`}>
        <LoginSocialTwitter
          client_id={process.env.REACT_APP_TWITTER_ID || ''}
          redirect_uri={REDIRECT_URI}
          onResolve={onResolve}
          onReject={(err: any) => {
            console.log(err, 'error');
          }}
        >
          <TwitterLoginButton />
        </LoginSocialTwitter>
      </div>
    </>
  );
};

export default Twitter;
