import { useState, useEffect } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import { loginApiCall } from '../redux/auth/authSlice';

function Google() {
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const handleLogout = () => {
    gapi.auth2.getAuthInstance().disconnect();
  };

  const onSuccess = (res: any) => {
    const decoded :any = jwt_decode(res.credential);
    setProfile(decoded.picture);
    const { name, sub, email, picture } = decoded;
    if (sub) {
      const registerResponse = {
        name: name,
        social_media_id: sub,
        type: 2,
        email,
        profile_icon: picture,
      };
      const loginResponse = {
        social_media_id: sub,
        type: 2,
      };
      dispatch(
        loginApiCall({ loginResponse, registerResponse, logout: handleLogout })
      );
    }
    setTimeout(() => {
      window.close();
    },5000);
  };


  const responseFailure = (err?: any) => {
  };

  return (
    <div className='google-container'>
      <div>
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin
            onSuccess={onSuccess} 
            onError={responseFailure} 
          />
          
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default Google;
