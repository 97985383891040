export const setLocalStorage = (key: string, data: any) => {
  if (typeof data === 'string') {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};
export const getLocalStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) || '{}');
};
export const clearLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const clearLocalItems = () => {
  localStorage.clear();
};

export const setToken = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const getLocalItem = (key: string) => localStorage.getItem(key);
