import React, { useState } from 'react';
import ReactFacebookLogin from 'react-facebook-login';
import { useDispatch } from 'react-redux';
import { loginApiCall } from '../redux/auth/authSlice';

export default function Facebook() {
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  const onResponse = (resp: any) => {
    setProfile(resp);
    const { name, id, email } = resp;
    if (id) {
      const registerResponse = {
        name,
        social_media_id: id,
        type: 1,
        email,
      };
      const loginResponse = {
        social_media_id: id,
        type: 1,
      };
      dispatch(loginApiCall({ loginResponse, registerResponse }));
    }
    setTimeout(() => {
      window.close();
    },3000);
  };
  console.log(profile);
  return (
    <div>
      <ReactFacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_ID || ''}
        autoLoad={true}
        fields='name,email,picture'
        callback={onResponse}
        onFailure={onResponse}
      />
    </div>
  );
}
