import { useEffect, useState } from 'react';
import './App.css';
import Facebook from './components/Facebook';
import Google from './components/Google';
import Twitter from './components/Twitter';
import LOGO from '../src/logo.svg';

function App() {
  const [socialMediaType, setSocialMediaType] = useState<string>('');
  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    const type: any = getParameterByName('type');
    setSocialMediaType(type);
  });

  return (
    <div className='main-container '>
      <img src={LOGO} alt='logo' style={{ marginTop: '40px' }} width='128px' />
      <p className='login-container'>
        {socialMediaType === 'google'
          ? 'Click below to join plugg using your google account'
          : socialMediaType === 'facebook'
          ? 'Click below to join plugg using your facebook account'
          : 'Click below to join plugg using your twitter account'}
      </p>
      {socialMediaType === 'google' ? (
        <Google />
      ) : socialMediaType === 'facebook' ? (
        <Facebook />
      ) : socialMediaType === 'twitter' ? (
        <Twitter />
      ) : (
        ''
      )}
    </div>
  );
}

export default App;
