import { LOGIN_URL, REGISTER_URL } from '../../constants/apiPath';
import { API } from '../../network/api';
import { clearLocalStorage } from '../../utils/SetLocal';
import { setLocalStorage } from '../../utils/SetLocal';
// import axios from 'axios';

export const fetchRegisterData = async (data: any) => {
  // const resp = await API.post(REGISTER_URL, data);
  try{
    const resp = await API.post(REGISTER_URL, data);
  setLocalStorage('login_data', resp);
  setLocalStorage('sess_data',resp)
  setTimeout(() => {
    clearLocalStorage('login_data');
    window.close();
  }, 3000);
  return resp.data;
} catch(err){
}
};

export const loginUser = async (data: any) => {
  const resp = await API.post(LOGIN_URL, data);
  setLocalStorage('login_data', resp);
  setLocalStorage('sess_data',resp)
  setTimeout(() => {
    clearLocalStorage('login_data');
    window.close();
  }, 3000);
  return resp.data;
};
