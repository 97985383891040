import axios from 'axios';
import { BASE_URL } from '../constants/apiPath';
import { requestHandler, successHandler, errorHandler } from './interceptor';

export const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Handle request process
API.interceptors.request.use((request: any) => requestHandler(request));
// Handle response process
API.interceptors.response.use(
  (response: any) => successHandler(response),
  (error: any) => errorHandler(error)
);
