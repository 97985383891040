import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearLocalStorage } from '../../utils/SetLocal';
import { fetchRegisterData, loginUser } from './authApi';

interface Istate {
  data: any;
}

interface Ipayload {
  loginResponse: {};
  registerResponse: {};
  logout: any;
}

const initialState: Istate = {
  data: {},
};

export const registerApiCall: any = createAsyncThunk(
  'getRegisterApiCall',
  async (payload: Ipayload) => {
    try {
      const resp: any = await fetchRegisterData(payload);
      if (resp) {
        setTimeout(() => {
          clearLocalStorage('login_data');
          window.close();
        }, 3000);
      }
      return resp;
    } catch (err: any) {
      return { mes: 'error ' };
    }
  }
);

export const loginApiCall: any = createAsyncThunk(
  'getLoginApiCall',
  async (payload: Ipayload, { dispatch }) => {
    try {
      const resp: any = await loginUser(payload.loginResponse);
      if (resp) {
        setTimeout(() => {
          clearLocalStorage('login_data');
          if (typeof payload.logout === 'function') {
            payload.logout();
          }
          window.close();
        }, 3000);
      }
      return resp;
    } catch (err: any) {
      const { message } = err;
      // if (typeof payload.logout === 'function') {
      //   payload.logout();
      // }
      if (message === "Request failed with status code 401") {
        dispatch(registerApiCall(payload.registerResponse));
      }
      return { mes: 'error ' };
    }
  }
);

const authSliceReducer = createSlice({
  name: 'authSliceReducer',
  initialState,
  reducers: {},
});

export default authSliceReducer.reducer;
